<template>
  <div class="mb-3 pending-avtivity">
    <div class="container timeline-scrolling">
      <div v-if="getJobTransactions.length">
        <div class="row mt-3">
          <div class="col-md-10">
            <ul class="timeline pt-3">
              <li
                v-for="(data, index) in getJobTransactions"
                :key="index"
                :name="`completed-job-${data.job_id}`"
              >
                <div @click="reReview(data, index)" style="cursor: pointer">
                  <time class="time">
                    <span>{{ data.initiated_date | formatDate }}</span>
                    <span>{{ data.initiated_date | formatTime }}</span>
                  </time>
                  <div class="icon bg-success">
                    <i class="fas fa-user-md" style="color: white"></i>
                  </div>
                  <div class="label desktop-view">
                    <CRow>
                      <CCol md="9">
                        <CRow>
                          <CCol md="3">
                            <h6 class="text-muted">Job</h6>
                          </CCol>
                          <CCol md="9" name="job-info">
                            <span class="px-1">:</span>
                            <span>{{ data.job.job_title }}</span> |
                            <!-- <span>{{ data.job_id }} </span> -->
                            <span>{{ data.job_display_uid }} </span>
                          </CCol>
                        </CRow>
                        <CRow name="salary_range">
                          <CCol md="3">
                            <h6 class="text-muted">Salary Range</h6>
                          </CCol>
                          <CCol md="9" name="salary-info">
                            <span class="px-1">:</span>
                            <span>{{ salaryRange(data) || "--" }}</span>
                          </CCol>
                        </CRow>
                        <CRow name="completed-by">
                          <CCol md="3">
                            <h6 class="text-muted">Completed By</h6>
                          </CCol>
                          <CCol md="9">
                            <span class="px-1">:</span>
                            <span>
                              <span>{{
                                data.initiated_by_user | getName
                              }}</span>
                              <div class="d-block pl-2">
                                <CBadge
                                  :color="
                                    data.status_id === 28
                                      ? `primary`
                                      : `success`
                                  "
                                  shape="pill"
                                  v-if="data.status && data.status_id !== 28"
                                  >{{ data.status || "--" }}</CBadge
                                >
                                <CBadge
                                  color="primary"
                                  shape="pill"
                                  class="mx-1"
                                  v-if="data.sub_status"
                                  >{{ data.sub_status || "--" }}</CBadge
                                >
                              </div>
                              <span class="d-block pl-2 fs-12"
                                >Comments({{
                                  data.total_transaction_count
                                }})</span
                              >
                            </span>
                          </CCol>
                        </CRow>
                        <!-- <CRow>
                          <CCol md="3">
                            <h6 class="text-muted">Completed By</h6>
                          </CCol>
                          <CCol md="9">
                            <span class="px-1">:</span>
                            <span>{{ data.completed_by_user | getName }}</span>
                          </CCol>
                        </CRow> -->
                      </CCol>
                      <CCol md="3" class="m-auto">
                        <span class="text-primary">
                          <span class="font-weight-light">Completed Date:</span>
                          <span class="pl-1 font-weight-bold d-block">{{
                            data.initiated_date | formatDate
                          }}</span>
                        </span>
                      </CCol>
                    </CRow>
                  </div>
                  <div class="label mobile-view">
                    <CRow>
                      <CCol col="3">
                        <h6 class="text-muted">Job</h6>
                      </CCol>
                      <CCol col="9">
                        <span class="pr-1">:</span>
                        <span>
                          <!-- {{ data.job.job_title }} | {{ data.job_id }}</span -->
                          {{ data.job.job_title }} |
                          {{ data.job_display_uid }}</span
                        >
                      </CCol>
                    </CRow>
                    <CRow>
                      <CCol col="3">
                        <h6 class="text-muted">Salary Range</h6>
                      </CCol>
                      <CCol col="9">
                        <span class="pr-1">:</span>
                        <span>{{ salaryRange(data) || "--" }}</span>
                      </CCol>
                    </CRow>
                    <CRow>
                      <CCol col="3">
                        <h6 class="text-muted">Completed By</h6>
                      </CCol>
                      <CCol col="9">
                        <span class="pr-1">:</span>
                        <span>{{ data.initiated_by_user | getName }}</span>
                        <div class="d-block pl-2">
                          <CBadge
                            :color="
                              data.status_id === 28 ? `primary` : `success`
                            "
                            shape="pill"
                            v-if="data.status"
                            >{{ data.status || "--" }}</CBadge
                          >
                          <CBadge
                            color="primary"
                            shape="pill"
                            class="mx-1"
                            v-if="data.sub_status"
                            >{{ data.sub_status || "--" }}</CBadge
                          >
                        </div>
                        <span class="d-block pl-2 fs-12"
                          >Comments({{ data.total_transaction_count }})</span
                        >
                      </CCol>
                    </CRow>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="text-center mt-2" v-if="noMoreDataFromActivityList">
          <CBadge color="primary" class="font-weight-normal ml-1 f-11"
            ><span>No More Completed Activity Found</span></CBadge
          >
        </div>
      </div>
      <p v-else class="text-muted h5 text-center p-4">No Completed Activity</p>
    </div>
  </div>
</template>
<script>
import m from "moment";
import { mapActions, mapGetters } from "vuex";
export default {
  props: {
    activityList: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapGetters([
      "getJobTransactions",
      "noMoreDataFromActivityList",
      "isFetchingActivityTransactions",
      "getCurrency",
    ]),
    completedActivity() {
      return (
        _(this.activityList)
          .filter(
            ({ completed_date, completed_by, candidate_uid, job_id }) =>
              completed_date &&
              completed_by &&
              candidate_uid === null &&
              job_id != null
          )
          .orderBy(["initiated_date"], ["desc"])
          .value() || []
      );
    },
  },
  filters: {
    formatTime(data) {
      if (data) return m.utc(data).local().format("hh:mm a");
      return "--";
    },
    formatDate(data) {
      if (data) return m.utc(data).local().format("Do MMM yyyy");
      return "--";
    },
    getName(data) {
      if (data) {
        return `${data?.first_name} ${data?.surname}` || "--";
      }
      return "--";
    },
  },
  methods: {
    ...mapActions(["fetchActivityTransactions", "resetJobTransactions"]),
    reReview(data, index) {
      const { job_id } = data;
      this.$router.push({
        path: `job-authoriser-activity-review/${job_id}`,
      });
    },
    onBodyScroll(e) {
      if (this.noMoreDataFromActivityList) return;
      if (
        e.target.clientHeight + e.target.scrollTop >=
          e.target.scrollHeight - 1 &&
        this.getJobTransactions.length &&
        !this.noMoreDataFromActivityList &&
        !this.isFetchingActivityTransactions
      ) {
        const to_date = m().format("YYYY-MM-DD");
        this.fetchActivityTransactions({
          action_id: 19,
          to_date,
          status_id__in: [28, 29],
          sub_status_id__in: [74, "null"],
          pagination: true,
          date_field: "initiated_date",
          action_for: "completed",
        });
      }
    },
    salaryRange(data) {
      if (data?.job?.salary_min && data?.job?.salary_max)
        return `${data?.job?.salary_min_text || data?.job?.salary_min} - ${
          data?.job?.salary_max_text || data?.job?.salary_max
        } ${this.currencyName(data.job.salary_currency_id)}`;
      return null;
    },
    currencyName(currency_id) {
      if (currency_id)
        return _.result(
          _.find(this.getCurrency, { code: currency_id }),
          "label"
        );
      return "";
    },
  },
  mounted() {
    const to_date = m().format("YYYY-MM-DD");
    this.resetJobTransactions().then(() => {
      this.fetchActivityTransactions({
        action_id: 19,
        to_date,
        status_id__in: [28, 29],
        sub_status_id__in: [74, "null"],
        pagination: false,
        date_field: "initiated_date",
        action_for: "completed",
      });
    });
    const ele = document.getElementById("c-body-app");
    if (ele) {
      ele.addEventListener("scroll", this.onBodyScroll);
    }
  },
  beforeDestroy() {
    const ele = document.getElementById("c-body-app");
    if (ele) {
      ele.removeEventListener("scroll", this.onBodyScroll);
    }
  },
};
</script>
<style lang="scss" scoped>
.fs-12 {
  font-size: 12px;
}
</style>
