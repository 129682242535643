<template>
  <div class="mb-3 pending-avtivity">
    <div class="container timeline-scrolling">
      <CTabs
        variant="tabs"
        :active-tab="activeSubTab"
        @update:activeTab="changeActiveSubTab"
        style="margin-top: 1rem"
      >
        <CTab>
          <template slot="title">
            New/Pending ({{ candidateInterviewerCount.interview.pending }})
          </template>
          <div class="row mt-3" v-if="getJobTransactions.length">
            <div class="col-md-10">
              <ul class="timeline pt-3">
                <li
                  v-for="(data, index) in getJobTransactions"
                  :key="index"
                  :style="{
                    'box-shadow': `10px 0px 0px 0px ${getBorderColor(
                      data.days
                    )}`,
                    'border-radius': '0px 10px 10px 0px',
                  }"
                  :name="`pending-cand-${data.candidate_uid}-job-${data.job_id}`"
                >
                  <time class="time">
                    <span>{{ data.initiated_date | formatDate }}</span>
                    <span>{{ data.initiated_date | formatTime }}</span>
                  </time>
                  <div class="icon bg-success">
                    <i class="fas fa-user-md" style="color: white"></i>
                  </div>
                  <div class="label desktop-view">
                    <CRow>
                      <CCol md="8">
                        <CRow>
                          <CCol md="3">
                            <h6 class="text-muted">Job</h6>
                          </CCol>
                          <CCol md="9">
                            <span class="px-1">:</span>
                            <span>{{ data.job.job_title }}</span> |
                            <!-- <span>{{ data.job_id }} </span> -->
                            <span>{{ data.job_display_uid }} </span>
                            <span class="d-block pl-2 fs-12">{{
                              data.display_text_days
                            }}</span>
                            <!-- <span class="d-block pl-2 fs-12">{{
                                data.expiry_date | daysLeft
                              }}</span> -->
                          </CCol>
                        </CRow>
                        <CRow>
                          <CCol md="3">
                            <h6 class="text-muted">Candidate</h6>
                          </CCol>
                          <CCol md="9">
                            <span class="px-1">:</span>
                            <span class="font-weight-bold">{{
                              data.candidate.full_name
                            }}</span>
                            |
                            <!-- <span>{{ data.candidate_uid }}</span> -->
                            <span class="font-weight-bold">{{
                              data.candidate_display_uid
                            }}</span>
                          </CCol>
                        </CRow>
                        <CRow>
                          <CCol md="3">
                            <h6 class="text-muted">Requested By</h6>
                          </CCol>
                          <CCol md="9">
                            <span class="px-1">:</span>
                            <span>
                              <span>{{
                                data.initiated_by_user | getName
                              }}</span>
                              <div class="d-block pl-2">
                                <CBadge
                                  color="primary"
                                  shape="pill"
                                  v-if="data.sub_status"
                                  >{{ data.sub_status || "--" }}</CBadge
                                >
                              </div>
                              <span class="d-block pl-2 fs-12"
                                >Comments({{
                                  data.total_transaction_count
                                }})</span
                              >
                            </span>
                          </CCol>
                        </CRow>
                      </CCol>
                      <CCol md="4" class="m-auto text-center">
                        <h6 class="text-primary">
                          {{ data.selected_slot_date | formatDate }} -
                          {{
                            convert2Date(
                              data.selected_slot_date,
                              data.selected_slot_start
                            ) | formatTime
                          }}
                        </h6>
                        <CButton
                          v-if="data.selected_slot_date"
                          name="interview-btn"
                          :id="`interview-cand-${data.candidate_uid}-job-${data.job_id}`"
                          color="primary"
                          @click="openInterview(data)"
                        >
                          Interview
                        </CButton>
                      </CCol>
                    </CRow>
                  </div>
                  <div class="label mobile-view">
                    <CRow>
                      <CCol col="3">
                        <h6 class="text-muted">Job</h6>
                      </CCol>
                      <CCol col="9">
                        <span class="pr-1">:</span>
                        <span>
                          <!-- {{ data.job.job_title }} | {{ data.job_id }}</span -->
                          {{ data.job.job_title }} |
                          {{ data.job_display_uid }}</span
                        >
                        <span class="d-block pl-2 fs-12">{{
                          data.display_text_days
                        }}</span>
                        <!-- <span class="d-block pl-2 fs-12">{{
                            data.expiry_date | daysLeft
                          }}</span> -->
                      </CCol>
                    </CRow>
                    <CRow>
                      <CCol col="3">
                        <h6 class="text-muted">Candidate</h6>
                      </CCol>
                      <CCol col="9">
                        <span class="pr-1">:</span>
                        <span class="font-weight-bold"
                          >{{ data.candidate.full_name || "--" }}
                        </span>
                        |
                        <!-- <span>{{ data.candidate_uid }}</span> -->
                        <span class="font-weight-bold">{{
                          data.candidate_display_uid
                        }}</span>
                      </CCol>
                    </CRow>
                    <CRow>
                      <CCol col="3">
                        <h6 class="text-muted">Requested By</h6>
                      </CCol>
                      <CCol col="9">
                        <span class="pr-1">:</span>
                        <span>{{ data.initiated_by_user | getName }}</span>
                        <div class="d-block pl-2">
                          <CBadge
                            color="primary"
                            shape="pill"
                            v-if="data.sub_status"
                            >{{ data.sub_status || "--" }}</CBadge
                          >
                        </div>
                        <span class="d-block pl-2 fs-12"
                          >Comments({{ data.total_transaction_count }})</span
                        >
                      </CCol>
                    </CRow>
                    <div class="text-center">
                      <h6 class="text-primary">
                        {{ data.selected_slot_date | formatDate }} -
                        {{
                          convert2Date(
                            data.selected_slot_date,
                            data.selected_slot_start
                          ) | formatTime
                        }}
                      </h6>
                    </div>
                    <div class="center mt-2">
                      <CButton
                        v-if="data.selected_slot_date"
                        color="primary"
                        @click="openInterview(data)"
                        class="px-4 py-1"
                      >
                        Interview
                      </CButton>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <p v-else class="text-muted h5 text-center p-4">
            No Pending Activity
          </p>
        </CTab>
        <CTab>
          <template slot="title">
            Completed ({{ candidateInterviewerCount.interview.completed }})
          </template>
          <div class="row mt-3" v-if="getJobTransactions.length">
            <div class="col-md-10">
              <ul class="timeline pt-3">
                <li
                  @click="reReview(data, index)"
                  v-for="(data, index) in getJobTransactions"
                  :key="index"
                  :name="`completed-cand-${data.candidate_uid}-job-${data.job_id}`"
                >
                  <div style="cursor: pointer">
                    <time class="time">
                      <span>{{ data.initiated_date | formatDate }}</span>
                      <span>{{ data.initiated_date | formatTime }}</span>
                    </time>
                    <div class="icon bg-success">
                      <i class="fas fa-user-md" style="color: white"></i>
                    </div>
                    <div class="label desktop-view">
                      <CRow>
                        <CCol md="9">
                          <CRow>
                            <CCol md="3">
                              <h6 class="text-muted">Job</h6>
                            </CCol>
                            <CCol md="9" name="job-info">
                              <span class="px-1">:</span>
                              <span name="job-title">{{
                                data.job.job_title
                              }}</span>
                              |
                              <!-- <span>{{ data.job_id }} </span> -->
                              <span :name="`job-id-${data.job_id}`"
                                >{{ data.job_display_uid }}
                              </span>
                            </CCol>
                          </CRow>
                          <CRow>
                            <CCol md="3">
                              <h6 class="text-muted">Candidate</h6>
                            </CCol>
                            <CCol md="9" name="candidate-info">
                              <span class="px-1">:</span>
                              <span class="font-weight-bold">{{
                                data.candidate.full_name
                              }}</span>
                              |
                              <!-- <span>{{ data.candidate_uid }}</span> -->
                              <span class="font-weight-bold">{{
                                data.candidate_display_uid
                              }}</span>
                            </CCol>
                          </CRow>
                          <CRow>
                            <CCol md="3">
                              <h6 class="text-muted">Completed By</h6>
                            </CCol>
                            <CCol md="9">
                              <span class="px-1">:</span>
                              <span>
                                <span>{{
                                  data.initiated_by_user | getName
                                }}</span>
                                <div class="d-block pl-2">
                                  <CBadge
                                    :color="
                                      data.status_id === 54
                                        ? 'success'
                                        : 'primary'
                                    "
                                    shape="pill"
                                    v-if="data.status"
                                    >{{ data.status || "--" }}</CBadge
                                  >
                                  <CBadge
                                    color="primary"
                                    shape="pill"
                                    v-if="data.sub_status"
                                    class="mx-1"
                                    >{{ data.sub_status || "--" }}</CBadge
                                  >
                                </div>
                                <span class="d-block pl-2 fs-12"
                                  >Comments({{
                                    data.total_transaction_count
                                  }})</span
                                >
                              </span>
                            </CCol>
                          </CRow>
                        </CCol>
                        <CCol md="3" class="m-auto">
                          <span class="text-primary">
                            <span class="font-weight-light"
                              >Completed Date:</span
                            >
                            <span class="pl-1 font-weight-bold d-block">{{
                              data.initiated_date | formatDate
                            }}</span>
                          </span>
                        </CCol>
                      </CRow>
                    </div>
                    <div class="label mobile-view">
                      <CRow>
                        <CCol col="3">
                          <h6 class="text-muted">Job</h6>
                        </CCol>
                        <CCol col="9">
                          <span class="pr-1">:</span>
                          <span>
                            <!-- {{ data.job.job_title }} | {{ data.job_id }}</span -->
                            {{ data.job.job_title }} |
                            {{ data.job_display_uid }}</span
                          >
                        </CCol>
                      </CRow>
                      <CRow>
                        <CCol col="3">
                          <h6 class="text-muted">Candidate</h6>
                        </CCol>
                        <CCol col="9">
                          <span class="pr-1">:</span>
                          <span class="font-weight-bold"
                            >{{ data.candidate.full_name }}
                          </span>
                          |
                          <!-- <span>{{ data.candidate_uid }}</span> -->
                          <span class="font-weight-bold">{{
                            data.candidate_display_uid
                          }}</span>
                        </CCol>
                      </CRow>
                      <CRow>
                        <CCol col="3">
                          <h6 class="text-muted">Completed By</h6>
                        </CCol>
                        <CCol col="9">
                          <span class="pr-1">:</span>
                          <span>{{ data.initiated_by_user | getName }}</span>
                          <div class="d-block pl-2">
                            <CBadge
                              :color="
                                data.status_id === 54 ? 'success' : 'primary'
                              "
                              shape="pill"
                              v-if="data.status"
                              >{{ data.status || "--" }}</CBadge
                            >
                            <CBadge
                              color="primary"
                              shape="pill"
                              v-if="data.sub_status"
                              class="mx-1"
                              >{{ data.sub_status || "--" }}</CBadge
                            >
                          </div>
                          <span class="d-block pl-2 fs-12"
                            >Comments({{ data.total_transaction_count }})</span
                          >
                        </CCol>
                      </CRow>
                      <div class="center mt-2">
                        <span class="text-primary">
                          <span class="font-weight-light">Completed Date:</span>
                          <span class="pl-1 font-weight-bold d-block">{{
                            data.initiated_date | formatDate
                          }}</span>
                        </span>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <p v-else class="text-muted h5 text-center p-4">
            No Completed Activity
          </p>
        </CTab>
        <CTab>
          <template slot="title">
            Expired ({{ candidateInterviewerCount.interview.expired }})
          </template>
          <InterviewerExpiredActivity />
        </CTab>
      </CTabs>
    </div>
    <InterviewModal
      v-if="interviewModal.isShowPopup"
      :isShowPopup="interviewModal.isShowPopup"
      :actionDetail="interviewModal.actionDetail"
      @modalCallBack="interviewModalCallBack"
    />
  </div>
</template>
<script>
import m from "moment";
import { mapActions, mapGetters } from "vuex";
import InterviewModal from "@/containers/ActivityList/CandidateInterviewer/InterviewModal";
import InterviewerExpiredActivity from "@/containers/ActivityList/CandidateInterviewer/InterviewerExpiredActivity";
import { getDaysFromDate } from "@/helpers/helper";

export default {
  props: {
    activityList: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapGetters([
      "noMoreDataFromActivityList",
      "getJobTransactions",
      "isFetchingActivityTransactions",
      "activeSubTab",
      "getJobCandidateProcessCounts",
    ]),
    candidateInterviewerCount() {
      return this.getJobCandidateProcessCounts["interviewer"];
    },
  },
  components: {
    InterviewModal,
    InterviewerExpiredActivity,
  },
  data() {
    return {
      interviewModal: {
        isShowPopup: false,
        actionDetail: null,
      },
    };
  },
  filters: {
    formatTime(data) {
      if (data) return m.utc(data).local().format("hh:mm A");
      return "--";
    },
    formatDate(data) {
      if (data) return m.utc(data).local().format("Do MMM yyyy");
      return "--";
    },
    getName(data) {
      if (data) {
        return `${data?.first_name} ${data?.surname}` || "--";
      }
      return "--";
    },
    daysLeft(data) {
      if (data) {
        const alertDate = m.utc(data).local();
        const todaysdate = m().set({
          hour: 0,
          minute: 0,
          second: 0,
          millisecond: 0,
        });
        const daysDiff = alertDate.diff(todaysdate, "days");
        return daysDiff >= 0
          ? `${daysDiff} Day${daysDiff > 1 ? "s" : ""} Left`
          : "Already Expired";
      }
      return "--";
    },
  },
  methods: {
    ...mapActions([
      "resetJobTransactions",
      "interviewFeedBack",
      "transactionTabCounts",
      "fetchActivityTransactions",
    ]),
    convert2Date(date, time) {
      if (date && time) return m(date + " " + time, "YYYY-MM-DD HH:mm:ss");
      return null;
    },
    reReview(data, index) {
      const { job_id, candidate_uid } = data;
      this.$router.push({
        path: `candidate-interviewer-activity-review/job/${job_id}/candidate/${candidate_uid}`,
      });
    },
    changeActiveSubTab(tab) {
      this.$store.commit("SET_ACTIVE_SUB_TAB", tab);
      if (tab === 0)
        this.fetchInterview([55], "expiry_date", "from_date", "pending", [
          "null",
        ]);
      if (tab === 1)
        this.fetchInterview([57, 47], null, "to_date", "completed");
      if (tab === 2) this.fetchExpiredActivity(false);

      // Reviewer/Interviewer
      let job_candidate_process = {
        action_id: 18,
        assigned_to: "yes",
        return_action_to: "yes",
      };

      this.fetchTransactionTabCounts(job_candidate_process);
    },
    onBodyScroll(e) {
      if (this.noMoreDataFromActivityList) return;
      if (
        e.target.clientHeight + e.target.scrollTop >=
          e.target.scrollHeight - 1 &&
        this.getJobTransactions.length &&
        !this.noMoreDataFromActivityList &&
        !this.isFetchingActivityTransactions
      ) {
        let sub_status, date_field, action_for;
        let date_dict = {};
        const date = m().format("YYYY-MM-DD");
        if (this.activeSubTab === 0) {
          sub_status = [55];
          date_field = "expiry_date";
          date_dict["from_date"] = date;
          action_for = "pending";
          completed_by__in = ["null"];
        }
        if (this.activeSubTab === 1) {
          sub_status = [57, 47];
          date_field = "initiated_date";
          date_dict["to_date"] = date;
          action_for = "completed";
        }
        if (this.activeSubTab === 2) {
          this.fetchExpiredActivity(true);
        } else {
          this.fetchActivityTransactions({
            ...date_dict,
            action_id: 18,
            status_id__in: [54],
            sub_status_id__in: sub_status,
            pagination: true,
            date_field: date_field,
            action_for,
          });
        }
      }
    },
    fetchInterview(
      sub_status,
      date_field,
      date_key,
      action_for,
      completed_by__in
    ) {
      const date = m().format("YYYY-MM-DD");
      let date_dict = {};
      if (date_key == "from_date") {
        date_dict["from_date"] = date;
      }
      if (date_key == "to_date") {
        date_dict["to_date"] = date;
      }
      if (date_field) date_dict["date_field"] = date_field;
      if (completed_by__in) date_dict["completed_by__in"] = completed_by__in;

      this.resetJobTransactions().then(() => {
        this.fetchActivityTransactions({
          ...date_dict,
          action_id: 18,
          status_id__in: [54],
          sub_status_id__in: sub_status,
          pagination: false,
          action_for,
        });
      });
    },
    openInterviewModal(data) {
      this.interviewModal.isShowPopup = true;
      this.interviewModal.actionDetail = data;
    },
    openInterview(data) {
      const { job_id, candidate_uid } = data;
      this.$router.push({
        path: `candidate-interviewer-activity-interview/job/${job_id}/candidate/${candidate_uid}`,
      });
    },
    interviewModalCallBack(action, payload) {
      if (action && payload) {
        this.interviewFeedBack(payload).then((res) => {
          this.interviewModal.isShowPopup = false;
          this.interviewModal.actionDetail = null;
        });
      } else {
        this.interviewModal.isShowPopup = false;
        this.interviewModal.actionDetail = null;
      }
    },
    getBorderColor(days) {
      if (!Number.isFinite(days)) return "none";
      if (days < 0) return "#dcd5d5";
      if (days == 0 || days == 1 || days == 2) return "#ff0000";
      if (days > 2 && days <= 4) return "#FFBF00";
      if (days >= 5) return "#008000";
      return "none";
    },
    fetchTransactionTabCounts(payload) {
      this.transactionTabCounts(payload);
    },
    fetchExpiredActivity(pagination) {
      if (pagination) {
        this.fetchExpiry(pagination);
      } else {
        this.resetJobTransactions().then(() => {
          this.fetchExpiry(pagination);
        });
      }
    },
    fetchExpiry(pagination) {
      const to_date = m().subtract(1, "days").format("YYYY-MM-DD");
      this.fetchActivityTransactions({
        action_id: 18,
        to_date,
        status_id__in: [54],
        sub_status_id__in: [55],
        pagination: pagination,
        completed_by__in: ["null"],
        date_field: "expiry_date",
        action_for: "pending",
      });
    },
  },
  mounted() {
    let sub_status, date_field, date_key, action_for, completed_by__in;
    if (this.activeSubTab === 0) {
      sub_status = [55];
      date_field = "expiry_date";
      date_key = "from_date";
      action_for = "pending";
      completed_by__in = ["null"];
    }
    if (this.activeSubTab === 1) {
      sub_status = [57, 47];
      date_key = "to_date";
      action_for = "completed";
    }

    if (this.activeSubTab === 2) {
      this.fetchExpiredActivity(false);
    } else {
      this.fetchInterview(
        sub_status,
        date_field,
        date_key,
        action_for,
        completed_by__in
      );
    }

    // Reviewer/Interviewer
    let job_candidate_process = {
      action_id: 18,
      assigned_to: "yes",
      return_action_to: "yes",
    };

    this.fetchTransactionTabCounts(job_candidate_process);

    const ele = document.getElementById("c-body-app");
    if (ele) {
      ele.addEventListener("scroll", this.onBodyScroll);
    }
  },
  beforeDestroy() {
    const ele = document.getElementById("c-body-app");
    if (ele) {
      ele.removeEventListener("scroll", this.onBodyScroll);
    }
  },
};
</script>
