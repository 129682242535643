<template>
  <div class="mb-3 pending-avtivity">
    <div class="container timeline-scrolling">
      <div v-if="getJobTransactions.length">
        <div class="row mt-3">
          <div class="col-md-10">
            <ul class="timeline pt-3">
              <li
                v-for="(data, index) in getJobTransactions"
                :key="index"
                :style="{
                  'box-shadow': `10px 0px 0px 0px ${getBorderColor(data.days)}`,
                  'border-radius': '0px 10px 10px 0px',
                }"
              >
                <time class="time">
                  <span>{{ formatDate(data.initiated_date) }}</span>
                  <span>{{ formatTime(data.initiated_date) }}</span>
                </time>
                <div class="icon bg-success">
                  <i class="fas fa-user-md" style="color: white"></i>
                </div>
                <div class="label desktop-view">
                  <CRow>
                    <CCol md="9">
                      <CRow>
                        <CCol md="3" class="mb-1">
                          <h6 class="text-muted mb-0">Job</h6>
                        </CCol>
                        <CCol md="9">
                          <span class="px-1">:</span>
                          <span>
                            <span>{{ data.job.job_title }}</span> |
                            <!-- <span>{{ data.job_id }} </span> -->
                            <span>{{ data.job_display_uid }} </span>
                            <span class="d-block pl-2 fs-12">{{
                              data.display_text_days
                            }}</span>
                            <!-- <span class="d-block pl-2 fs-12">{{
                              data.expiry_date | daysLeft
                            }}</span> -->
                          </span>
                        </CCol>
                      </CRow>
                      <CRow>
                        <CCol md="3" class="mb-1">
                          <h6 class="text-muted mb-0">Candidate</h6>
                        </CCol>
                        <CCol md="9">
                          <span class="px-1">:</span>
                          <span class="font-weight-bold"
                            >{{ getCandidateName(data.candidate) }}
                          </span>
                          |
                          <!-- <span>{{ data.candidate_uid }}</span> -->
                          <span class="font-weight-bold">{{
                            data.candidate_display_uid
                          }}</span>
                        </CCol>
                      </CRow>
                      <CRow>
                        <CCol md="3">
                          <h6 class="text-muted">Requested By</h6>
                        </CCol>
                        <CCol md="9">
                          <span class="px-1">:</span>
                          <span>
                            <span>{{ data.initiated_by_user | getName }}</span>
                            <div class="d-block pl-2">
                              <CBadge
                                color="primary"
                                shape="pill"
                                v-if="data.sub_status"
                                >{{ data.sub_status || "--" }}</CBadge
                              >
                            </div>
                            <span class="d-block pl-2 fs-12"
                              >Comments({{
                                data.total_transaction_count
                              }})</span
                            >
                          </span>
                        </CCol>
                      </CRow>
                    </CCol>
                    <CCol md="3" class="m-auto">
                      <CButton color="primary" @click="openReview(data, index)">
                        Review
                      </CButton>
                    </CCol>
                  </CRow>
                </div>
                <div class="label mobile-view">
                  <CRow>
                    <CCol col="3">
                      <h6 class="text-muted">Job</h6>
                    </CCol>
                    <CCol col="9">
                      <span class="pr-1">:</span>
                      <!-- <span>{{ data.job.job_title }} | {{ data.job_id }}</span> -->
                      <span
                        >{{ data.job.job_title }} |
                        {{ data.job_display_uid }}</span
                      >
                      <span class="d-block pl-2 fs-12">{{
                        data.display_text_days
                      }}</span>
                      <!-- <span class="d-block pl-2 fs-12">{{
                        data.expiry_date | daysLeft
                      }}</span> -->
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol col="3">
                      <h6 class="text-muted">Candidate</h6>
                    </CCol>
                    <CCol col="9">
                      <span class="pr-1">:</span>
                      <span class="font-weight-bold"
                        >{{ getCandidateName(data.candidate) }}
                      </span>
                      |
                      <!-- <span>{{ data.candidate_uid }}</span> -->
                      <span class="font-weight-bold">{{
                        data.candidate_display_uid
                      }}</span>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol col="3">
                      <h6 class="text-muted">Requested By</h6>
                    </CCol>
                    <CCol col="9">
                      <span class="pr-1">:</span>
                      <span>{{ data.initiated_by_user | getName }}</span>
                      <div class="d-block pl-2">
                        <CBadge
                          color="primary"
                          shape="pill"
                          v-if="data.sub_status"
                          >{{ data.sub_status || "--" }}</CBadge
                        >
                      </div>
                      <span class="d-block pl-2 fs-12"
                        >Comments({{ data.total_transaction_count }})</span
                      >
                    </CCol>
                  </CRow>
                  <div class="center mt-2">
                    <CButton
                      color="primary right"
                      @click="openReview(data, index)"
                      class="px-3 py-1"
                    >
                      Review
                    </CButton>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="text-center mt-2" v-if="noMoreDataFromActivityList">
          <CBadge color="primary" class="font-weight-normal ml-1 f-11"
            ><span>No More Activity Found</span></CBadge
          >
        </div>
      </div>
      <p v-else class="text-muted h5 text-center p-4">No Expired Activity</p>
    </div>
  </div>
</template>
<script>
import m from "moment";
import { mapActions, mapGetters } from "vuex";
import { getDaysFromDate } from "@/helpers/helper";

export default {
  props: {
    activityList: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapGetters([
      "getJobTransactions",
      "noMoreDataFromActivityList",
      "isFetchingActivityTransactions",
    ]),
    pendingActivity() {
      return (
        _(this.activityList)
          .filter(
            ({
              completed_date,
              completed_by,
              candidate_uid,
              job_id,
              status_id,
              sub_status_id,
            }) =>
              completed_date === null &&
              completed_by === null &&
              candidate_uid != null &&
              job_id != null &&
              status_id === 52 &&
              sub_status_id === 53
          )
          .orderBy(["initiated_date"], ["desc"])
          .value() || []
      );
    },
  },
  methods: {
    ...mapActions(["resetJobTransactions", "fetchActivityTransactions"]),
    formatTime(data) {
      return m.utc(data).local().format("hh:mm A") || "--";
    },
    formatDate(data) {
      return m.utc(data).local().format("Do MMM yyyy") || "--";
    },
    openReview(data, index) {
      const { job_id, candidate_uid } = data;
      this.$router.push({
        path: `candidate-reviewer-activity-review/job/${job_id}/candidate/${candidate_uid}`,
      });
    },
    getJobTitle(job) {
      return (job && job?.job_title) || "--";
    },
    getCandidateName(candidate) {
      return (candidate && candidate?.full_name) || "--";
    },
    onBodyScroll(e) {
      if (this.noMoreDataFromActivityList) return;
      if (
        e.target.clientHeight + e.target.scrollTop >=
          e.target.scrollHeight - 1 &&
        this.getJobTransactions.length &&
        !this.noMoreDataFromActivityList &&
        !this.isFetchingActivityTransactions
      ) {
        const to_date = m().subtract(1, "days").format("YYYY-MM-DD");
        this.fetchActivityTransactions({
          action_id: 18,
          to_date,
          status_id__in: [52],
          sub_status_id__in: [53, 44],
          pagination: true,
          date_field: "expiry_date",
          action_for: "pending",
        });
      }
    },
    getBorderColor(days) {
      if (!Number.isFinite(days)) return "none";
      if (days < 0) return "#dcd5d5";
      if (days == 0 || days == 1 || days == 2) return "#ff0000";
      if (days > 2 && days <= 4) return "#FFBF00";
      if (days >= 5) return "#008000";
      return "none";
    },
  },
  mounted() {
    const to_date = m().subtract(1, "days").format("YYYY-MM-DD");
    this.resetJobTransactions().then(() => {
      this.fetchActivityTransactions({
        action_id: 18,
        to_date,
        status_id__in: [52],
        sub_status_id__in: [53, 44],
        pagination: false,
        completed_by__in: ["null"],
        date_field: "expiry_date",
        action_for: "pending",
      });
    });
    const ele = document.getElementById("c-body-app");
    if (ele) {
      ele.addEventListener("scroll", this.onBodyScroll);
    }
  },
  beforeDestroy() {
    const ele = document.getElementById("c-body-app");
    if (ele) {
      ele.removeEventListener("scroll", this.onBodyScroll);
    }
  },
  filters: {
    getName(data) {
      if (data) {
        return `${data?.first_name} ${data?.surname}` || "--";
      }
      return "--";
    },
    daysLeft(data) {
      if (data) {
        const alertDate = m.utc(data).local();
        const todaysdate = m().set({
          hour: 0,
          minute: 0,
          second: 0,
          millisecond: 0,
        });
        const daysDiff = alertDate.diff(todaysdate, "days");
        return daysDiff >= 0
          ? `${daysDiff} Day${daysDiff > 1 ? "s" : ""} Left`
          : "Already Expired";
      }
      return "--";
    },
  },
};
</script>