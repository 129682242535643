<template>
  <CModal
    color="primary"
    :show.sync="isShowPopup"
    :closeOnBackdrop="false"
    size="lg"
  >
    <template #header>
      <h5>Interview</h5>
      <CButtonClose @click="modalCallBack()" />
    </template>
    <template #footer>
      <CButton color="secondary" @click="modalCallBack(false)">Close</CButton>
      <CButton color="primary" @click="onSubmit()">Submit</CButton>
    </template>
    <div v-if="actionDetail">
      <h6 class="text-primary">Select Slot</h6>
      <table
        class="table table-section table-striped border-bottom mb-3"
        style="table-layout: fixed"
      >
        <thead>
          <th>Date</th>
          <th>Start Time</th>
          <th>End Time</th>
        </thead>
        <tbody>
          <tr>
            <td>{{ actionDetail.selected_slot_date | formatDate }}</td>
            <td>{{ actionDetail.selected_slot_start | formatTime }}</td>
            <td>{{ actionDetail.selected_slot_end | formatTime }}</td>
          </tr>
        </tbody>
      </table>
      <div>
        <CRow>
          <CCol lg="8">
            <span class="px-1">Interview Link:</span>
            <a
              :href="`${interview_link}`"
              target="_blank"
              :class="{ disabled: isExpired }"
              >{{ interview_link }}</a
            >
          </CCol>
          <CCol lg="3">
            <CButton color="primary" :disabled="isExpired" @click="openMeet">{{
              isExpired ? `Expired` : `Join`
            }}</CButton>
          </CCol>
        </CRow>
        <ValidationObserver ref="interviewForm" v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(onSubmit)" name="interviewForm">
            <CRow>
              <CCol lg="6">
                <CRow class="mb-3">
                  <label class="col-lg-12 required">Comments</label>
                  <div class="col-lg-12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <TextInput
                        name="comments"
                        :value="interview.comments"
                        label="comments"
                        @input="handleInput"
                        :error="errors[0]"
                      ></TextInput>
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
            </CRow>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </CModal>
</template>
<script>
import m from "moment";
import TextInput from "@/components/reusable/Fields/TextInput";
import Vue from "vue";
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import { mapGetters } from "vuex";
extend("required", { ...required, message: "This field is required" });
export default {
  props: {
    isShowPopup: {
      type: Boolean,
      default: () => false,
    },
    actionDetail: {
      type: Object,
      default: () => null,
    },
  },
  components: {
    TextInput,
  },
  data() {
    return {
      interview: {},
      payload: {},
    };
  },
  computed: {
    isExpired() {
      if (this.actionDetail?.selected_slot_date) {
        let today = new Date();

        let end_date = new Date(this.actionDetail?.selected_slot_date);
        const end_date_split = this.actionDetail?.selected_slot_end.split(":");
        end_date_split
          ? end_date.setHours(
              end_date_split[0],
              end_date_split[1],
              end_date_split[2]
            )
          : null;
        return today > end_date;
      }
      return false;
    },
    interview_link() {
      return this.actionDetail &&
        this.actionDetail?.interview_link &&
        this.actionDetail?.interview_link.indexOf("://") === -1
        ? "http://" + this.actionDetail?.interview_link
        : this.actionDetail?.interview_link;
    },
  },
  methods: {
    ...mapGetters(["showToast"]),
    handleInput(name, value) {
      Vue.set(this.interview, name, value);
    },
    async onSubmit() {
      const isValid = await this.$refs.interviewForm.validate();
      if (!isValid) {
        this.showToast({
          class: "bg-danger text-white",
          message: "please fill mandatory fields!",
        });
        return;
      }
      const { job_id, action_detail_id, candidate_uid } = this.actionDetail;
      this.payload = {
        job_id,
        action_detail_id,
        candidate_uid,
        comments: this.interview?.comments,
      };
      this.modalCallBack(true, this.payload);
    },
    modalCallBack(action, payload) {
      this.$emit("modalCallBack", action, payload);
    },
    openMeet() {
      let routeData = `${this.interview_link}`;
      window.open(routeData, "_blank");
    },
  },
  filters: {
    formatDate(date) {
      if (date) return m(date).format("DD MMM YYYY");
      return "--";
    },
    formatTime(date) {
      if (date) {
        const dateObj = new Date();
        const dateSplit = date.split(":");
        dateObj.setHours(dateSplit[0], dateSplit[1], dateSplit[2]);
        return m(dateObj).format("hh:mm A");
      }
      return "--";
    },
  },
};
</script>
<style lang="scss" scoped>
.btn-primary.disabled {
  cursor: not-allowed;
}
a.disabled {
  pointer-events: none;
  cursor: default;
}
</style>