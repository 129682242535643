<template>
  <div class="mb-3 pending-avtivity">
    <div class="container timeline-scrolling">
      <CTabs
        variant="tabs"
        :active-tab="activeSubTab"
        @update:activeTab="changeActiveSubTab"
        style="margin-top: 1rem"
      >
        <CTab>
          <template slot="title">
            New/Pending ({{ candidateInterviewerCount.schedule.pending }})
          </template>
          <div class="row mt-3" v-if="getJobTransactions.length">
            <div class="col-md-10">
              <ul class="timeline pt-3">
                <li
                  v-for="(data, index) in getJobTransactions"
                  :key="index"
                  :style="{
                    'box-shadow': `10px 0px 0px 0px ${getBorderColor(
                      data.days
                    )}`,
                    'border-radius': '0px 10px 10px 0px',
                  }"
                >
                  <time class="time">
                    <span>{{ formatDate(data.initiated_date) }}</span>
                    <span>{{ formatTime(data.initiated_date) }}</span>
                  </time>
                  <div class="icon bg-success">
                    <i class="fas fa-user-md" style="color: white"></i>
                  </div>
                  <div class="label desktop-view">
                    <CRow>
                      <CCol md="9">
                        <CRow>
                          <CCol md="3">
                            <h6 class="text-muted">Job</h6>
                          </CCol>
                          <CCol md="9">
                            <span class="px-1">:</span>
                            <span>{{ data.job.job_title }}</span> |
                            <!-- <span>{{ data.job_id }} </span> -->
                            <span>{{ data.job_display_uid }} </span>
                            <span class="d-block pl-2 fs-12">{{
                              data.display_text_days
                            }}</span>
                            <!-- <span class="d-block pl-2 fs-12">{{
                                data.expiry_date | daysLeft
                              }}</span> -->
                          </CCol>
                        </CRow>
                        <CRow>
                          <CCol md="3">
                            <h6 class="text-muted">Candidate</h6>
                          </CCol>
                          <CCol md="9">
                            <span class="px-1">:</span>
                            <span class="font-weight-bold">{{
                              data.candidate.full_name
                            }}</span>
                            |
                            <!-- <span>{{ data.candidate_uid }}</span> -->
                            <span class="font-weight-bold">{{
                              data.candidate_display_uid
                            }}</span>
                          </CCol>
                        </CRow>
                        <CRow>
                          <CCol md="3">
                            <h6 class="text-muted">Requested By</h6>
                          </CCol>
                          <CCol md="9">
                            <span class="px-1">:</span>
                            <span>
                              <span>{{
                                data.initiated_by_user | getName
                              }}</span>
                              <div class="d-block pl-2">
                                <CBadge
                                  color="primary"
                                  shape="pill"
                                  v-if="data.sub_status"
                                  >{{ data.sub_status || "--" }}</CBadge
                                >
                              </div>
                              <span class="d-block pl-2 fs-12"
                                >Comments({{
                                  data.total_transaction_count
                                }})</span
                              >
                            </span>
                          </CCol>
                        </CRow>
                      </CCol>
                      <CCol md="3" class="m-auto">
                        <!-- <CButton
                            v-if="data.selected_slot_date"
                            color="primary"
                            @click="openInterviewModal(data)"
                          >
                            Interview
                          </CButton> -->
                        <CButton
                          v-if="!data.selected_slot_date"
                          name="schedule-btn"
                          :id="`schedule-candidate-${data.candidate_uid}-job-${data.job_id}`"
                          color="primary"
                          @click="openReview(data, index)"
                        >
                          Schedule
                        </CButton>
                      </CCol>
                    </CRow>
                  </div>
                  <div class="label mobile-view">
                    <CRow>
                      <CCol col="3">
                        <h6 class="text-muted">Job</h6>
                      </CCol>
                      <CCol col="9">
                        <span class="pr-1">:</span>
                        <!-- <span>{{ data.job.job_title }} | {{ data.job_id }}</span> -->
                        <span
                          >{{ data.job.job_title }} |
                          {{ data.job_display_uid }}</span
                        >
                        <span class="d-block pl-2 fs-12">{{
                          data.display_text_days
                        }}</span>
                        <!-- <span class="d-block pl-2 fs-12">{{
                            data.expiry_date | daysLeft
                          }}</span> -->
                      </CCol>
                    </CRow>
                    <CRow>
                      <CCol col="3">
                        <h6 class="text-muted">Candidate</h6>
                      </CCol>
                      <CCol col="9">
                        <span class="pr-1">:</span>
                        <span class="font-weight-bold"
                          >{{ data.candidate.full_name || "--" }}
                        </span>
                        |
                        <!-- <span>{{ data.candidate_uid }}</span> -->
                        <span class="font-weight-bold">{{
                          data.candidate_display_uid
                        }}</span>
                      </CCol>
                    </CRow>
                    <CRow>
                      <CCol col="3">
                        <h6 class="text-muted">Requested By</h6>
                      </CCol>
                      <CCol col="9">
                        <span class="pr-1">:</span>
                        <span>{{ data.initiated_by_user | getName }}</span>
                        <div class="d-block pl-2">
                          <CBadge
                            color="primary"
                            shape="pill"
                            v-if="data.sub_status"
                            >{{ data.sub_status || "--" }}</CBadge
                          >
                        </div>
                        <span class="d-block pl-2 fs-12"
                          >Comments({{ data.total_transaction_count }})</span
                        >
                      </CCol>
                    </CRow>
                    <div class="center mt-2">
                      <!-- <CButton
                          v-if="data.selected_slot_date"
                          color="primary"
                          @click="openInterviewModal(data)"
                          class="px-4 py-1"
                        >
                          Interview
                        </CButton> -->
                      <CButton
                        v-if="!data.selected_slot_date"
                        name="schedule-btn"
                        :id="`schedule-candidate-${data.candidate_uid}-job-${data.job_id}`"
                        color="primary"
                        @click="openReview(data, index)"
                        class="px-3 py-1"
                      >
                        Schedule
                      </CButton>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <p v-else class="text-muted h5 text-center p-4">
            No Pending Activity
          </p>
        </CTab>
        <CTab>
          <template slot="title">
            Completed ({{ candidateInterviewerCount.schedule.completed }})
          </template>
          <div class="row mt-3" v-if="getJobTransactions.length">
            <div class="col-md-10">
              <ul class="timeline pt-3">
                <li
                  @click="reReview(data, index)"
                  v-for="(data, index) in getJobTransactions"
                  :key="index"
                  :name="`completed-cand-${data.candidate_uid}-job-${data.job_id}`"
                >
                  <div style="cursor: pointer">
                    <time class="time">
                      <span>{{ formatDate(data.initiated_date) }}</span>
                      <span>{{ formatTime(data.initiated_date) }}</span>
                    </time>
                    <div class="icon bg-success">
                      <i class="fas fa-user-md" style="color: white"></i>
                    </div>
                    <div class="label desktop-view">
                      <CRow>
                        <CCol md="9">
                          <CRow>
                            <CCol md="3">
                              <h6 class="text-muted">Job</h6>
                            </CCol>
                            <CCol md="9" name="job-info">
                              <span class="px-1">:</span>
                              <span>{{ data.job.job_title }}</span> |
                              <!-- <span>{{ data.job_id }} </span> -->
                              <span>{{ data.job_display_uid }} </span>
                            </CCol>
                          </CRow>
                          <CRow>
                            <CCol md="3">
                              <h6 class="text-muted">Candidate</h6>
                            </CCol>
                            <CCol md="9" name="candidate-info">
                              <span class="px-1">:</span>
                              <span class="font-weight-bold">{{
                                data.candidate.full_name
                              }}</span>
                              |
                              <!-- <span>{{ data.candidate_uid }}</span> -->
                              <span class="font-weight-bold">{{
                                data.candidate_display_uid
                              }}</span>
                            </CCol>
                          </CRow>
                          <CRow>
                            <CCol md="3">
                              <h6 class="text-muted">Completed By</h6>
                            </CCol>
                            <CCol md="9">
                              <span class="px-1">:</span>
                              <span>
                                <span name="initiated-by">{{
                                  data.initiated_by_user | getName
                                }}</span>
                                <div class="d-block pl-2">
                                  <CBadge
                                    :color="
                                      data.status_id === 54
                                        ? 'success'
                                        : 'primary'
                                    "
                                    shape="pill"
                                    v-if="data.status"
                                    >{{ data.status || "--" }}</CBadge
                                  >
                                  <CBadge
                                    color="primary"
                                    shape="pill"
                                    v-if="data.sub_status"
                                    class="mx-1"
                                    >{{ data.sub_status || "--" }}</CBadge
                                  >
                                </div>
                                <span class="d-block pl-2 fs-12"
                                  >Comments({{
                                    data.total_transaction_count
                                  }})</span
                                >
                              </span>
                            </CCol>
                          </CRow>
                        </CCol>
                        <CCol md="3" class="m-auto">
                          <span class="text-primary">
                            <span class="font-weight-light"
                              >Completed Date:</span
                            >
                            <span class="pl-1 font-weight-bold d-block">{{
                              data.initiated_date | formatDate
                            }}</span>
                          </span>
                        </CCol>
                      </CRow>
                    </div>
                    <div class="label mobile-view">
                      <CRow>
                        <CCol col="3">
                          <h6 class="text-muted">Job</h6>
                        </CCol>
                        <CCol col="9">
                          <span class="pr-1">:</span>
                          <!-- <span>{{ data.job.job_title }} | {{ data.job_id }}</span> -->
                          <span
                            >{{ data.job.job_title }} |
                            {{ data.job_display_uid }}</span
                          >
                        </CCol>
                      </CRow>
                      <CRow>
                        <CCol col="3">
                          <h6 class="text-muted">Candidate</h6>
                        </CCol>
                        <CCol col="9">
                          <span class="pr-1">:</span>
                          <span class="font-weight-bold"
                            >{{ data.candidate.full_name }}
                          </span>
                          |
                          <!-- <span>{{ data.candidate_uid }}</span> -->
                          <span class="font-weight-bold">{{
                            data.candidate_display_uid
                          }}</span>
                        </CCol>
                      </CRow>
                      <CRow>
                        <CCol col="3">
                          <h6 class="text-muted">Completed By</h6>
                        </CCol>
                        <CCol col="9">
                          <span class="pr-1">:</span>
                          <span>{{ data.initiated_by_user | getName }}</span>
                          <div class="d-block pl-2">
                            <CBadge
                              :color="
                                data.status_id === 54 ? 'success' : 'primary'
                              "
                              shape="pill"
                              v-if="data.status"
                              >{{ data.status || "--" }}</CBadge
                            >
                            <CBadge
                              color="primary"
                              shape="pill"
                              v-if="data.sub_status"
                              class="mx-1"
                              >{{ data.sub_status || "--" }}</CBadge
                            >
                          </div>
                          <span class="d-block pl-2 fs-12"
                            >Comments({{ data.total_transaction_count }})</span
                          >
                        </CCol>
                      </CRow>
                      <div class="center mt-2">
                        <span class="text-primary">
                          <span class="font-weight-light">Completed Date:</span>
                          <span class="pl-1 font-weight-bold d-block">{{
                            data.initiated_date | formatDate
                          }}</span>
                        </span>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <p v-else class="text-muted h5 text-center p-4">
            No Completed Activity
          </p>
        </CTab>
        <CTab>
          <template slot="title">
            Expired ({{ candidateInterviewerCount.schedule.expired }})
          </template>
          <ScheduledExpiredActivity />
        </CTab>
      </CTabs>
    </div>
    <InterviewModal
      v-if="interviewModal.isShowPopup"
      :isShowPopup="interviewModal.isShowPopup"
      :actionDetail="interviewModal.actionDetail"
      @modalCallBack="interviewModalCallBack"
    />
  </div>
</template>
<script>
import m from "moment";
import InterviewModal from "@/containers/ActivityList/CandidateInterviewer/InterviewModal";
import ScheduledExpiredActivity from "@/containers/ActivityList/CandidateInterviewer/ScheduledExpiredActivity";
import { mapActions, mapGetters } from "vuex";
import { getDaysFromDate } from "@/helpers/helper";

export default {
  props: {
    activityList: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    InterviewModal,
    ScheduledExpiredActivity,
  },
  data() {
    return {
      interviewModal: {
        isShowPopup: false,
        actionDetail: null,
      },
    };
  },
  computed: {
    ...mapGetters([
      "noMoreDataFromActivityList",
      "isFetchingActivityTransactions",
      "getJobTransactions",
      "activeSubTab",
      "getJobCandidateProcessCounts",
    ]),
    candidateInterviewerCount() {
      return this.getJobCandidateProcessCounts["interviewer"];
    },
    pendingActivity() {
      return (
        _(this.activityList)
          .filter(
            ({
              completed_date,
              completed_by,
              candidate_uid,
              job_id,
              status_id,
              sub_status_id,
            }) =>
              completed_date === null &&
              completed_by === null &&
              candidate_uid != null &&
              job_id != null &&
              status_id === 54 &&
              [51, 55].includes(sub_status_id)
          )
          .orderBy(["initiated_date"], ["desc"])
          .value() || []
      );
    },
  },
  methods: {
    ...mapActions([
      "interviewFeedBack",
      "resetJobTransactions",
      "fetchActivityTransactions",
      "transactionTabCounts",
    ]),
    formatTime(data) {
      return m.utc(data).local().format("hh:mm A") || "--";
    },
    formatDate(data) {
      return m.utc(data).local().format("Do MMM yyyy") || "--";
    },
    openReview(data, index) {
      const { job_id, candidate_uid } = data;
      this.$router.push({
        path: `candidate-interviewer-activity-review/job/${job_id}/candidate/${candidate_uid}`,
      });
    },
    openInterviewModal(data) {
      this.interviewModal.isShowPopup = true;
      this.interviewModal.actionDetail = data;
    },
    interviewModalCallBack(action, payload) {
      if (action && payload) {
        this.interviewFeedBack(payload).then((res) => {
          this.interviewModal.isShowPopup = false;
          this.interviewModal.actionDetail = null;
        });
      } else {
        this.interviewModal.isShowPopup = false;
        this.interviewModal.actionDetail = null;
      }
    },
    onBodyScroll(e) {
      if (this.noMoreDataFromActivityList) return;
      if (
        e.target.clientHeight + e.target.scrollTop >=
          e.target.scrollHeight - 1 &&
        this.getJobTransactions.length &&
        !this.noMoreDataFromActivityList &&
        !this.isFetchingActivityTransactions
      ) {
        let sub_status, date_field, action_for;
        let date_dict = {},
          completed_by_dict = {};
        const date = m().format("YYYY-MM-DD");
        if (this.activeSubTab === 0) {
          sub_status = [51];
          date_field = "expiry_date";
          date_dict["from_date"] = date;
          action_for = "pending";
          completed_by_dict = {
            completed_by__in: ["null"],
          };
        }
        if (this.activeSubTab === 1) {
          sub_status = ["null", 55, 47];
          date_field = "initiated_date";
          date_dict["to_date"] = date;
          action_for = "completed";
        }

        if (this.activeSubTab === 2) {
          this.fetchExpiredActivity(true);
        } else {
          this.fetchActivityTransactions({
            ...date_dict,
            ...completed_by_dict,
            action_id: 18,
            status_id__in: [54],
            sub_status_id__in: sub_status,
            pagination: true,
            date_field: date_field,
            action_for,
          });
        }
      }
    },
    changeActiveSubTab(tab) {
      this.$store.commit("SET_ACTIVE_SUB_TAB", tab);
      if (tab === 0)
        this.fetchSchedule([51], "expiry_date", "from_date", "pending");
      if (tab === 1)
        this.fetchSchedule(["null", 55, 47], null, "to_date", "completed");
      if (tab === 2) this.fetchExpiredActivity(false);

      // Reviewer/Interviewer
      let job_candidate_process = {
        action_id: 18,
        assigned_to: "yes",
        return_action_to: "yes",
      };

      this.fetchTransactionTabCounts(job_candidate_process);
    },
    fetchSchedule(sub_status, date_field, date_key, action_for) {
      const date = m().format("YYYY-MM-DD");
      let date_dict = {},
        completed_by_dict = {};
      if (date_key == "from_date") {
        date_dict["from_date"] = date;
      }
      if (date_key == "to_date") {
        date_dict["to_date"] = date;
      }
      if (action_for == "pending") {
        completed_by_dict = {
          completed_by__in: ["null"],
        };
      }
      if (date_field) date_dict["date_field"] = date_field;

      this.resetJobTransactions().then(() => {
        this.fetchActivityTransactions({
          ...date_dict,
          ...completed_by_dict,
          action_id: 18,
          status_id__in: [54],
          sub_status_id__in: sub_status,
          pagination: false,
          action_for,
        });
      });
    },
    reReview(data, index) {
      const { job_id, candidate_uid } = data;
      this.$router.push({
        path: `candidate-interviewer-activity-review/job/${job_id}/candidate/${candidate_uid}`,
      });
    },
    getBorderColor(days) {
      if (!Number.isFinite(days)) return "none";
      if (days < 0) return "#dcd5d5";
      if (days == 0 || days == 1 || days == 2) return "#ff0000";
      if (days > 2 && days <= 4) return "#FFBF00";
      if (days >= 5) return "#008000";
      return "none";
    },
    fetchTransactionTabCounts(payload) {
      this.transactionTabCounts(payload);
    },
    fetchExpiredActivity(pagination) {
      if (pagination) {
        this.fetchExpiry(pagination);
      } else {
        this.resetJobTransactions().then(() => {
          this.fetchExpiry(pagination);
        });
      }
    },
    fetchExpiry(pagination) {
      const to_date = m().subtract(1, "days").format("YYYY-MM-DD");
      this.fetchActivityTransactions({
        action_id: 18,
        to_date,
        status_id__in: [54],
        sub_status_id__in: [51],
        pagination: pagination,
        completed_by__in: ["null"],
        date_field: "expiry_date",
        action_for: "pending",
      });
    },
  },
  mounted() {
    let sub_status, date_field, date_key, action_for;
    if (this.activeSubTab === 0) {
      sub_status = [51];
      date_field = "expiry_date";
      date_key = "from_date";
      action_for = "pending";
    }
    if (this.activeSubTab === 1) {
      sub_status = ["null", 55, 47];
      date_key = "to_date";
      action_for = "completed";
    }

    if (this.activeSubTab === 2) {
      this.fetchExpiredActivity(false);
    } else {
      this.fetchSchedule(sub_status, date_field, date_key, action_for);
    }

    // Reviewer/Interviewer
    let job_candidate_process = {
      action_id: 18,
      assigned_to: "yes",
      return_action_to: "yes",
    };

    this.fetchTransactionTabCounts(job_candidate_process);

    const ele = document.getElementById("c-body-app");
    if (ele) {
      ele.addEventListener("scroll", this.onBodyScroll);
    }
  },
  beforeDestroy() {
    const ele = document.getElementById("c-body-app");
    if (ele) {
      ele.removeEventListener("scroll", this.onBodyScroll);
    }
  },
  filters: {
    getName(data) {
      if (data) {
        return `${data?.first_name} ${data?.surname}` || "--";
      }
      return "--";
    },
    daysLeft(data) {
      if (data) {
        const alertDate = m.utc(data).local();
        const todaysdate = m().set({
          hour: 0,
          minute: 0,
          second: 0,
          millisecond: 0,
        });
        const daysDiff = alertDate.diff(todaysdate, "days");
        return daysDiff >= 0
          ? `${daysDiff} Day${daysDiff > 1 ? "s" : ""} Left`
          : "Already Expired";
      }
      return "--";
    },
  },
};
</script>