<template>
  <CModal color="primary" :show.sync="isShowPopup" :closeOnBackdrop="false">
    <template #header>
      <h5>Pending Action Requests</h5>
      <CButtonClose @click="modalCallBack()" />
    </template>
    <template #footer>
      <CButton color="secondary" @click="modalCallBack()">Close</CButton>
    </template>
    <div>
      <table class="table">
        <thead>
          <th>User Type</th>
          <th>Count</th>
          <th></th>
        </thead>
        <tbody>
          <tr v-for="(data, index) in tabCounts" :key="index">
            <td>
              {{ data.label
              }}<small v-if="checkCurrentUserType(data)">(current)</small>
            </td>
            <td>{{ data.count }}</td>
            <td>
              <CButton color="primary" @click="modalCallBack(data)"
                >Switch</CButton
              >
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </CModal>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  props: {
    isShowPopup: {
      type: Boolean,
      default: () => false,
    },
    activityList: {
      type: Array,
      default: () => [],
    },
    customerTypes: {
      type: Array,
      default: () => [],
    },
    currentUserType: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      checkingFields: {
        1: {
          candidate_uid: { isNull: false },
          job_id: { isNull: false },
          status_id: { check: [52] },
          sub_status_id: { check: [53, 44] },
        },
        2: {
          candidate_uid: { isNull: false },
          job_id: { isNull: false },
          status_id: { check: 54 },
          sub_status_id: { check: [51, 55] },
        },
        4: {
          candidate_uid: { isNull: true },
          job_id: { isNull: false },
        },
      },
    };
  },
  computed: {
    ...mapGetters([
      "userTypes",
      "getJobApprovalProcessCounts",
      "getJobCandidateProcessCounts",
    ]),
    pendingAction() {
      return this.customerTypes.map((e) => {
        const condi = this.checkingFields[e.code];
        const activity =
          this.activityList.filter((val) => {
            let r = val.completed_date === null && val.completed_by === null;
            let r1 = true;
            if (r && condi) {
              for (const key in condi) {
                let value = condi[key];
                if (r1) {
                  if (value?.check && Array.isArray(value?.check)) {
                    r1 = r1 ? value?.check.includes(val[key]) : false;
                  } else if (value?.check) {
                    r1 = r1 ? val[key] === value?.check : false;
                  }
                  if (value?.isNull) {
                    r1 = r1 ? val[key] == null : false;
                  } else if (value?.isNull == false) {
                    r1 = r1 ? val[key] != null : false;
                  }
                }
              }
            }
            return r & r1;
          }) || [];
        return { ...e, count: activity.length };
      });
    },
    tabCounts() {
      return this.customerTypes
        .filter((v) => v.code != 6)
        .map((obj) => {
          // Job Authroiser
          if (obj.code === 4) {
            obj.count =
              this.getJobApprovalProcessCounts["job_authorizer"]["pending"];
          }
          // Reviewer
          if (obj.code === 1) {
            obj.count =
              this.getJobCandidateProcessCounts["reviewer"]["pending"];
          }
          // Interviewer
          if (obj.code === 2) {
            let interviewerCounts =
              this.getJobCandidateProcessCounts["interviewer"];
            obj.count =
              interviewerCounts["schedule"]["pending"] +
              interviewerCounts["interview"]["pending"];
          }
          return obj;
        });
    },
  },
  methods: {
    pendingActionCount(customerType) {
      let checkingFields = {};
      //Candidate Reviewer
      if (customerType.code === 1) {
        checkingFields = {
          ...checkingFields,
          1: {
            candidate_uid: { isNull: false },
            job_id: { isNull: false },
            status_id: { check: 52 },
            sub_status_id: { check: 53 },
          },
        };
      }
      //Job Authroiser
      if (customerType.code === 4) {
        checkingFields = {
          ...checkingFields,
          4: {
            candidate_uid: { isNull: true },
            job_id: { isNull: false },
          },
        };
      }
      //interviewer
      if (customerType.code === 2) {
        checkingFields = {
          ...checkingFields,
          2: {
            candidate_uid: { isNull: false },
            job_id: { isNull: false },
            status_id: { check: 54 },
            sub_status_id: { check: [51, 55] },
          },
        };
      }
      return (
        (
          this.activityList &&
          this.activityList.filter((val) => {
            let r = val.completed_date === null && val.completed_by === null;
            let r1 = true;
            if (r && checkingFields[customerType.code]) {
              for (const [key, value] of Object.entries(
                checkingFields[customerType.code]
              )) {
                if (r1) {
                  if (value?.isNull) {
                    r1 = r1 ? val[key] == null : false;
                  } else if (value?.isNull == false) {
                    r1 = r1 ? val[key] != null : false;
                  }
                  if (value?.check && Array.isArray(value?.check)) {
                    r1 = r1 ? value?.check.includes(val[key]) : false;
                  } else if (value?.check) {
                    r1 = r1 ? val[key] === value?.check : false;
                  }
                }
              }
            }
            return r & r1;
          })
        ).length || 0
      );
    },
    checkCurrentUserType(data) {
      return this.currentUserType?.code === data?.code;
    },
    modalCallBack(data) {
      this.$emit("modalCallBack", data);
    },
  },
};
</script>