<template>
  <div class="activity-list py-2">
    <LoadingOverlay v-if="isFetchingActivityList" />
    <div v-else>
      <h4 class="text-primary pb-2">
        Activity List<span class="text-primary" v-if="isCandidateInterviewer">
          :
          <span style="font-size: large"
            >{{ pageTitle
            }}<small v-if="title" class="text-danger font-weight-bolder mx-2"
              >[{{ title }}]</small
            >
          </span></span
        >
      </h4>
      <div v-if="isJobAuthoriser">
        <CTabs
          variant="tabs"
          :active-tab="activeTab"
          @update:activeTab="changeActiveTab"
        >
          <CTab>
            <template slot="title">
              New ({{ jobAuthoriserCount.new }})
            </template>
            <h5 class="welcome py-1 px-0 mb-0 ml-0 mt-3 font-weight-bold">
              Hello {{ userName + ", " }}
              <span
                class="message"
                style="font-size: 15px; font-weight: normal"
              >
                the following jobs need your authorisation.
              </span>
            </h5>
            <AuthoriserNewActivity
              :activityList="activityList"
              v-if="activeTab === 0"
            />
          </CTab>
          <CTab>
            <template slot="title">
              Pending ({{ jobAuthoriserCount.pending }})
            </template>
            <h5 class="welcome py-1 px-0 mb-0 ml-0 mt-3 font-weight-bold">
              Hello {{ userName + ", " }}
              <span
                class="message"
                style="font-size: 15px; font-weight: normal"
              >
                the following jobs need your authorisation.
              </span>
            </h5>
            <AuthoriserPendingActivity
              :activityList="activityList"
              v-if="activeTab === 1"
            />
          </CTab>
          <CTab>
            <template slot="title">
              Completed ({{ jobAuthoriserCount.completed }})
            </template>
            <AuthoriserCompletedActivity
              :activityList="activityList"
              v-if="activeTab === 2"
            />
          </CTab>
          <CTab>
            <template slot="title">
              Expired ({{ jobAuthoriserCount.expired }})
            </template>
            <AuthoriserExpiredActivity
              :activityList="activityList"
              v-if="activeTab === 3"
            />
          </CTab>
        </CTabs>
      </div>
      <div v-else-if="isCandidateReviewer">
        <CTabs
          variant="tabs"
          :active-tab="activeTab"
          @update:activeTab="changeActiveTab"
        >
          <CTab>
            <template slot="title">
              New ({{ candidateReviewerCount.new }})
            </template>
            <h5 class="welcome py-1 px-0 mb-0 ml-0 mt-3 font-weight-bold">
              Hello {{ userName + ", " }}
              <span
                class="message"
                style="font-size: 15px; font-weight: normal"
              >
                the following candidates are available for your review.
              </span>
            </h5>
            <ReviewerNewActivity
              :activityList="activityList"
              v-if="activeTab === 0"
            />
          </CTab>
          <CTab>
            <template slot="title">
              Pending ({{ candidateReviewerCount.pending }})
            </template>
            <h5 class="welcome py-1 px-0 mb-0 ml-0 mt-3 font-weight-bold">
              Hello {{ userName + ", " }}
              <span
                class="message"
                style="font-size: 15px; font-weight: normal"
              >
                the following candidates are available for your review.
              </span>
            </h5>
            <ReviewerPendingActivity
              :activityList="activityList"
              v-if="activeTab === 1"
            />
          </CTab>
          <CTab>
            <template slot="title">
              Completed ({{ candidateReviewerCount.completed }})
            </template>
            <ReviewerCompletedActivity
              :activityList="activityList"
              v-if="activeTab === 2"
            />
          </CTab>
          <CTab>
            <template slot="title">
              Expired ({{ candidateReviewerCount.expired }})
            </template>
            <ReviewerExpiredActivity
              :activityList="activityList"
              v-if="activeTab === 3"
            />
          </CTab>
        </CTabs>
      </div>
      <div v-else-if="isCandidateInterviewer">
        <CTabs
          variant="tabs"
          :active-tab="activeTab"
          @update:activeTab="changeActiveTab"
        >
          <CTab>
            <template slot="title">
              Schedule ({{ candidateInterviewerCount.schedule.pending }})
            </template>
            <h5 class="welcome py-1 px-0 mb-0 ml-0 mt-3 font-weight-bold">
              Hello {{ userName + ", " }}
              <span
                class="message"
                style="font-size: 15px; font-weight: normal"
              >
                the following candidates are available for Schedule.
              </span>
            </h5>
            <InterViewerPendingActivity
              :activityList="activityList"
              v-if="activeTab === 0"
            />
          </CTab>
          <CTab>
            <template slot="title">
              Interview ({{ candidateInterviewerCount.interview.pending }})
            </template>
            <h5 class="welcome py-1 px-0 mb-0 ml-0 mt-3 font-weight-bold">
              Hello {{ userName + ", " }}
              <span
                class="message"
                style="font-size: 15px; font-weight: normal"
              >
                the following candidates are available for Interview.
              </span>
            </h5>
            <InterViewerCompletedActivity
              :activityList="activityList"
              v-if="activeTab === 1"
            />
          </CTab>
        </CTabs>
      </div>
    </div>
    <UserTypeModal
      v-if="userTypeModal.isShowPopup"
      :isShowPopup="userTypeModal.isShowPopup"
      :activityList="activityList"
      :customerTypes="customerUserRoles"
      :currentUserType="currentUserType"
      @modalCallBack="userTypeModalCallBack"
    />
  </div>
</template>
<script>
import ReviewerNewActivity from "@/containers/ActivityList/CandidateReviewer/NewActivity";
import ReviewerPendingActivity from "@/containers/ActivityList/CandidateReviewer/PendingActivity";
import ReviewerCompletedActivity from "@/containers/ActivityList/CandidateReviewer/CompletedActivity";
import ReviewerExpiredActivity from "@/containers/ActivityList/CandidateReviewer/ExpiredActivity";
import AuthoriserNewActivity from "@/containers/ActivityList/JobAuthoriser/NewActivity";
import AuthoriserPendingActivity from "@/containers/ActivityList/JobAuthoriser/PendingActivity";
import AuthoriserCompletedActivity from "@/containers/ActivityList/JobAuthoriser/CompletedActivity";
import AuthoriserExpiredActivity from "@/containers/ActivityList/JobAuthoriser/ExpiredActivity";
import InterViewerPendingActivity from "@/containers/ActivityList/CandidateInterviewer/PendingActivity";
import InterViewerCompletedActivity from "@/containers/ActivityList/CandidateInterviewer/CompletedActivity";
import UserTypeModal from "@/containers/ActivityList/UserTypeModal";
import { mapActions, mapGetters } from "vuex";
import m from "moment";
import {
  Role,
  getScope,
  getRoleScopes,
  isEmptyObjectCheck,
  isObject,
} from "@/helpers/helper";
import router from "../../router";

export default {
  components: {
    ReviewerNewActivity,
    ReviewerPendingActivity,
    ReviewerCompletedActivity,
    ReviewerExpiredActivity,
    AuthoriserNewActivity,
    AuthoriserPendingActivity,
    AuthoriserCompletedActivity,
    AuthoriserExpiredActivity,
    UserTypeModal,
    InterViewerPendingActivity,
    InterViewerCompletedActivity,
  },
  data() {
    return {
      userTypeModal: {
        isShowPopup: false,
      },
      pageTitle: "Schedule",
    };
  },
  computed: {
    ...mapGetters([
      "activityList",
      "isFetchingActivityList",
      "getIsJobAuthoriser",
      "getIsCandidateReviewer",
      "getIsCandidateReviewer",
      "currentUserType",
      "customerUserRoles",
      "isShowUserTypeModal",
      "getUser",
      "activeTab",
      "noMoreDataFromActivityList",
      "isCustomerRecruiterUser",
      "getJobApprovalProcessCounts",
      "getJobCandidateProcessCounts",
      "getCurrency",
      "getUserDetails",
      "customerUserRoles",
    ]),
    isCandidateReviewer() {
      return this.currentUserType?.code === 1 || false;
    },
    isJobAuthoriser() {
      return this.currentUserType?.code === 4 || false;
    },
    isCandidateInterviewer() {
      return this.currentUserType?.code === 2 || false;
    },
    userName() {
      return this.getUser.first_name + " " + this.getUser.surname;
    },
    jobAuthoriserCount() {
      return this.getJobApprovalProcessCounts["job_authorizer"];
    },
    candidateReviewerCount() {
      return this.getJobCandidateProcessCounts["reviewer"];
    },
    candidateInterviewerCount() {
      return this.getJobCandidateProcessCounts["interviewer"];
    },
    title() {
      if (this.isCandidateInterviewer) {
        const schedulePending =
          this.getJobCandidateProcessCounts?.interviewer?.schedule?.pending;
        const interviewPending =
          this.getJobCandidateProcessCounts?.interviewer?.interview?.pending;
        if (schedulePending && interviewPending)
          return `You have new Schedule/Interview`;
        else if (schedulePending)
          return `You have ${schedulePending} new Schedule`;
        else if (interviewPending)
          return `You have ${interviewPending} new Interview`;
      }
      return null;
    },
  },
  methods: {
    ...mapActions([
      "fetchActivityList",
      "fetchUserType",
      "fetchAllPendingActivity",
      "transactionTabCounts",
      "fetchCurrency",
      "fetchUser",
    ]),
    userTypeModalCallBack(data) {
      if (data) {
        this.$store.commit("SET_CURRENT_USER_TYPE", data);
        this.$store.commit("SET_ACTIVE_TAB", 0);
        this.$store.commit("SET_ACTIVE_SUB_TAB", 0);
      }
      this.userTypeModal.isShowPopup = false;
      this.$store.commit("SET_IS_SHOW_USER_TYPE_MODAL", false);
      let title = "";
      if (this.isCandidateInterviewer)
        title = this.activeTab == 0 ? "Schedule" : "Interview";
      this.pageTitle = title;
    },
    changeActiveTab(tab) {
      this.$store.commit("SET_ACTIVE_TAB", tab);
      this.$store.commit("SET_ACTIVE_SUB_TAB", 0);

      // Job Authorizer
      let job_approval_process = {
        action_id: 19,
        assigned_to: "yes",
        return_action_to: "yes",
      };

      // Reviewer/Interviewer
      let job_candidate_process = {
        action_id: 18,
        assigned_to: "yes",
        return_action_to: "yes",
      };
      if (this.isJobAuthoriser) {
        this.fetchTransactionTabCounts(job_approval_process);
      } else {
        this.fetchTransactionTabCounts(job_candidate_process);
      }

      let title = "";
      if (this.isCandidateInterviewer)
        title = tab == 0 ? "Schedule" : "Interview";
      this.pageTitle = title;
    },
    fetchTransactionTabCounts(payload) {
      this.transactionTabCounts(payload);
    },
  },
  async mounted() {
    let appendAction = [];
    let user_role = (await this.getUser?.users_roles)
      ? this.getUser.users_roles[0].type_name
      : "";
    if (user_role === Role.jobAdvertiser) {
      this.$router.replace("/public/job/home");
      return;
    }
    const to_date = m().format("YYYY-MM-DD");

    // if (this.isCandidateInterviewer)
    // appendAction = [...appendAction, this.fetchActivityList()];
    // else
    // appendAction = [...appendAction, this.fetchAllPendingActivity({ to_date })];

    // Job Authorizer
    let job_approval_process = {
      action_id: 19,
      assigned_to: "yes",
      return_action_to: "yes",
    };

    // Reviewer/Interviewer
    let job_candidate_process = {
      action_id: 18,
      assigned_to: "yes",
      return_action_to: "yes",
    };
    if (this.isJobAuthoriser)
      appendAction = [
        ...appendAction,
        this.fetchTransactionTabCounts(job_approval_process),
      ];
    else {
      appendAction = [
        ...appendAction,
        this.fetchTransactionTabCounts(job_candidate_process),
      ];
    }
    if (!(isObject(this.getUser) && !isEmptyObjectCheck(this.getUser)))
      appendAction = [...appendAction, this.fetchUser()];
    Promise.all(appendAction).then((res) => {
      if (this.currentUserType?.code === 6) {
        this.$router.push("/management-report");
        return;
      } else {
        let user_roles =
          this.getUser?.users_roles?.filter((v) => v?.id != 6) || [];
        if (
          user_roles.length &&
          user_roles.length > 1 &&
          this.isShowUserTypeModal &&
          !this.isCustomerRecruiterUser
        ) {
          this.userTypeModal.isShowPopup = true;
        }
        let title = "";
        if (this.isCandidateInterviewer)
          title = this.activeTab == 0 ? "Schedule" : "Interview";
        this.pageTitle = title;
      }
    });
    if (!this.getCurrency.length) {
      this.fetchCurrency();
    }
  },
  created() {
    if (getScope() != Role.customerUser && !this.currentUserType?.code)
      this.$router.push("/recruiter-dashboard");
    const currentUserType = this.currentUserType;
    const {
      query: { user_type },
    } = this.$router.currentRoute;
    if (currentUserType?.code === "customer_recruiter" && user_type) {
      const currentRole = this.customerUserRoles.find(({code})=>code == user_type)
      if(currentRole)
      this.$store.commit("SET_CURRENT_USER_TYPE", currentRole); 
    }
  },
};
</script>