<template>
  <div class="mb-3 pending-avtivity">
    <div class="container timeline-scrolling">
      <div v-if="getJobTransactions.length">
        <div class="row mt-3">
          <div class="col-md-10">
            <ul class="timeline pt-3">
              <li
                @click="reReview(data, index)"
                v-for="(data, index) in getJobTransactions"
                :key="index"
                :name="`completed-cand-${data.candidate_uid}-job-${data.job_id}`"
              >
                <div style="cursor: pointer">
                  <time class="time">
                    <span>{{ data.initiated_date | formatDate }}</span>
                    <span>{{ data.initiated_date | formatTime }}</span>
                  </time>
                  <div class="icon bg-success">
                    <i class="fas fa-user-md" style="color: white"></i>
                  </div>
                  <div class="label desktop-view">
                    <CRow>
                      <CCol md="9">
                        <CRow>
                          <CCol md="3" class="mb-1">
                            <h6 class="text-muted mb-0">Job</h6>
                          </CCol>
                          <CCol md="9" name="job-info">
                            <span class="px-1">:</span>
                            <span>{{ data.job.job_title }}</span> |
                            <!-- <span>{{ data.job_id }} </span> -->
                            <span>{{ data.job_display_uid }} </span>
                          </CCol>
                        </CRow>
                        <CRow>
                          <CCol md="3" class="mb-1">
                            <h6 class="text-muted mb-0">Candidate</h6>
                          </CCol>
                          <CCol md="9" name="candidate-info">
                            <span class="px-1">:</span>
                            <span class="font-weight-bold"
                              >{{ getCandidateName(data.candidate) }}
                            </span>
                            |
                            <!-- <span>{{ data.candidate_uid }}</span> -->
                            <span class="font-weight-bold">{{
                              data.candidate_display_uid
                            }}</span>
                          </CCol>
                        </CRow>
                        <CRow>
                          <CCol md="3">
                            <h6 class="text-muted">Completed By</h6>
                          </CCol>
                          <CCol md="9">
                            <span class="px-1">:</span>
                            <span>
                              <span>{{
                                data.initiated_by_user | getName
                              }}</span>
                              <div class="d-block pl-2">
                                <!-- <CBadge
                                  :color="
                                    data.status_id === 54
                                      ? 'success'
                                      : 'primary'
                                  "
                                  shape="pill"
                                  v-if="data.status"
                                  >{{ data.status || "--" }}</CBadge
                                > -->
                                <CBadge
                                  :color="
                                    data.status_id === 54
                                      ? 'success'
                                      : 'primary'
                                  "
                                  shape="pill"
                                  v-if="data.sub_status"
                                  class="mx-1"
                                  >{{ data.sub_status || "--" }}</CBadge
                                >
                              </div>
                              <span class="d-block pl-2 fs-12"
                                >Comments({{
                                  data.total_transaction_count
                                }})</span
                              >
                            </span>
                          </CCol>
                        </CRow>
                      </CCol>
                      <CCol md="3" class="m-auto">
                        <span class="text-primary">
                          <span class="font-weight-light">Completed Date:</span>
                          <span class="pl-1 font-weight-bold d-block">{{
                            data.initiated_date | formatDate
                          }}</span>
                        </span>
                      </CCol>
                    </CRow>
                  </div>
                  <div class="label mobile-view">
                    <CRow>
                      <CCol col="3">
                        <h6 class="text-muted">Job</h6>
                      </CCol>
                      <CCol col="9">
                        <span class="pr-1">:</span>
                        <span>
                          <!-- {{ data.job.job_title }} | {{ data.job_id }}</span -->
                          {{ data.job.job_title }} |
                          {{ data.job_display_uid }}</span
                        >
                      </CCol>
                    </CRow>
                    <CRow>
                      <CCol col="3">
                        <h6 class="text-muted">Candidate</h6>
                      </CCol>
                      <CCol col="9" name="candidate-info">
                        <span class="pr-1">:</span>
                        <span class="font-weight-bold"
                          >{{ getCandidateName(data.candidate) }}
                        </span>
                        |
                        <!-- <span>{{ data.candidate_uid }}</span> -->
                        <span class="font-weight-bold">{{
                          data.candidate_display_uid
                        }}</span>
                      </CCol>
                    </CRow>
                    <CRow>
                      <CCol col="3">
                        <h6 class="text-muted">Completed By</h6>
                      </CCol>
                      <CCol col="9">
                        <span class="pr-1">:</span>
                        <span>{{ data.initiated_by_user | getName }}</span>
                        <div class="d-block pl-2">
                          <CBadge
                            :color="
                              data.status_id === 54 ? 'success' : 'primary'
                            "
                            shape="pill"
                            v-if="data.status"
                            >{{ data.status || "--" }}</CBadge
                          >
                          <CBadge
                            color="primary"
                            shape="pill"
                            v-if="data.sub_status"
                            class="mx-1"
                            >{{ data.sub_status || "--" }}</CBadge
                          >
                        </div>
                        <span class="d-block pl-2 fs-12"
                          >Comments({{ data.total_transaction_count }})</span
                        >
                      </CCol>
                    </CRow>
                    <div class="center mt-2">
                      <span class="text-primary">
                        <span class="font-weight-light">Completed Date:</span>
                        <span class="pl-1 font-weight-bold d-block">{{
                          data.initiated_date | formatDate
                        }}</span>
                      </span>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="text-center mt-2" v-if="noMoreDataFromActivityList">
          <CBadge color="primary" class="font-weight-normal ml-1 f-11"
            ><span>No More Pending Activity Found</span></CBadge
          >
        </div>
      </div>
      <p v-else class="text-muted h5 text-center p-4">No Completed Activity</p>
    </div>
  </div>
</template>
<script>
import m from "moment";
import { mapActions, mapGetters } from "vuex";
export default {
  props: {
    activityList: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapGetters([
      "getJobTransactions",
      "noMoreDataFromActivityList",
      "isFetchingActivityTransactions",
    ]),
  },
  filters: {
    getName(data) {
      if (data) {
        return `${data?.first_name} ${data?.surname}` || "--";
      }
      return "--";
    },
    formatTime(data) {
      if (data) return m.utc(data).local().format("hh:mm a");
      return "--";
    },
    formatDate(data) {
      if (data) return m.utc(data).local().format("Do MMM yyyy");
      return "--";
    },
  },
  methods: {
    ...mapActions(["resetJobTransactions", "fetchActivityTransactions"]),
    getCandidateName(candidate) {
      return (candidate && candidate?.full_name) || "--";
    },
    reReview(data, index) {
      const { job_id, candidate_uid } = data;
      this.$router.push({
        path: `candidate-reviewer-activity-review/job/${job_id}/candidate/${candidate_uid}`,
      });
    },
    onBodyScroll(e) {
      if (this.noMoreDataFromActivityList) return;
      if (
        e.target.clientHeight + e.target.scrollTop >=
          e.target.scrollHeight - 1 &&
        this.getJobTransactions.length &&
        !this.noMoreDataFromActivityList &&
        !this.isFetchingActivityTransactions
      ) {
        this.fetchActivityTransactions({
          action_id: 18,
          status_id__in: [54, 52],
          sub_status_id__in: [50, 47],
          pagination: true,
          action_for: "completed",
        });
      }
    },
  },
  mounted() {
    this.resetJobTransactions().then(() => {
      this.fetchActivityTransactions({
        action_id: 18,
        status_id__in: [54, 52],
        sub_status_id__in: [50, 47],
        pagination: false,
        action_for: "completed",
      });
    });
    const ele = document.getElementById("c-body-app");
    if (ele) {
      ele.addEventListener("scroll", this.onBodyScroll);
    }
  },
  beforeDestroy() {
    const ele = document.getElementById("c-body-app");
    if (ele) {
      ele.removeEventListener("scroll", this.onBodyScroll);
    }
  },
};
</script>
